<template>
  <div class="gph-20">
    <div class="gpv-20 g-flex g-justify-space-between g-align-center gbd-b">
      <div>
        <span style="margin-right:100px" class="gfw-b gfs-18">绑定手机</span>
        <span class="gc-aaa">{{userInfo.mobile}}</span>
      </div>
      <a-button type="link" @click="$router.push('/personal/security/updatePhone')">更换手机</a-button>
    </div>
    <div class="gpv-20 g-flex g-justify-space-between g-align-center gbd-b">
      <div>
        <span style="margin-right:100px" class="gfw-b gfs-18">邮箱</span>
        <span class="gc-aaa">{{userInfo.email}}</span>
      </div>
      <a-button type="link" @click="$router.push('/personal/security/updateEmail')">更换邮箱</a-button>
    </div>
    <div class="gpv-20 g-flex g-justify-space-between g-align-center gbd-b">
      <div>
        <span style="margin-right:100px" class="gfw-b gfs-18">登录密码</span>
        <span class="gc-aaa">已设置</span>
      </div>
      <a-button type="link" @click="$router.push('/personal/security/updatePassword')">修改密码</a-button>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    },
  },
}
</script>